@value LIGHT_BLUE, NEUTRAL_XXDARK from "./common/colors.module.css";

.headerContainer {
  display: flex;
  flex-direction: column;
}
.headerText {
  color: NEUTRAL_XXDARK;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.horizontalLine {
  background: LIGHT_BLUE;
  height: 1px;
  margin-bottom: 24px;
  margin-top: 24px;
}
