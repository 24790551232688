@value BUTTON_BLUE_LIGHTEST, BUTTON_BLUE_MEDIUM, BUTTON_BLUE_DARK, BUTTON_BLUE_DARKEST, NEUTRAL_LIGHTEST from "./common/colors.module.css";

.filledContainer {
  composes: filledContainer from './ChipButtonBase.module.css';
  background-color: BUTTON_BLUE_MEDIUM;
}
.filledContainer:focus {
  background-color: BUTTON_BLUE_DARK;
}
.filledContainer:hover {
  background-color: BUTTON_BLUE_DARK;
}
.filledContainer:hover:active {
  background-color: BUTTON_BLUE_DARKEST;
}
.disabledFilledContainer {
  composes: disabledFilledContainer from './ChipButtonBase.module.css';
}

.borderedContainer {
  composes: borderedContainer from './ChipButtonBase.module.css';
  border: 1px solid BUTTON_BLUE_DARK;
}
.borderedContainer:focus {
  border: 5px double BUTTON_BLUE_DARK;
}
.borderedContainer:hover {
  background-color: BUTTON_BLUE_LIGHTEST;
}
.borderedContainer:hover:active {
  background-color: BUTTON_BLUE_DARKEST;
}
.disabledBorderedContainer {
  composes: disabledBorderedContainer from './ChipButtonBase.module.css';
}

.mainColorLabel {
  composes: label from './ChipButtonBase.module.css';
  color: BUTTON_BLUE_DARK;
}
.grayLabel {
  composes: grayLabel from './ChipButtonBase.module.css';
}
.whiteLabel {
  composes: whiteLabel from './ChipButtonBase.module.css';
}

.mainColorIcon {
  composes: mainColorIcon from './ChipButtonBase.module.css';
  color: BUTTON_BLUE_DARK;
}
.grayIcon {
  composes: grayIcon from './ChipButtonBase.module.css';
}
.whiteIcon {
  composes: whiteIcon from './ChipButtonBase.module.css';
}
