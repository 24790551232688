@value FEEDBACK_DANGER_LIGHTEST, FEEDBACK_DANGER_MEDIUM, FEEDBACK_DANGER_DARK, FEEDBACK_DANGER_DARKEST, NEUTRAL_LIGHTEST from "./common/colors.module.css";

.filledContainer {
  composes: filledContainer from './ChipButtonBase.module.css';
  background-color: FEEDBACK_DANGER_MEDIUM;
}
.filledContainer:focus {
  background-color: FEEDBACK_DANGER_DARK;
}
.filledContainer:hover {
  background-color: FEEDBACK_DANGER_DARK;
}
.filledContainer:hover:active {
  background-color: FEEDBACK_DANGER_DARKEST;
}
.disabledFilledContainer {
  composes: disabledFilledContainer from './ChipButtonBase.module.css';
}

.borderedContainer {
  composes: borderedContainer from './ChipButtonBase.module.css';
  border: 1px solid FEEDBACK_DANGER_DARK;
}
.borderedContainer:focus {
  border: 5px double FEEDBACK_DANGER_DARK;
}
.borderedContainer:hover {
  background-color: FEEDBACK_DANGER_LIGHTEST;
}
.borderedContainer:hover:active {
  background-color: FEEDBACK_DANGER_DARKEST;
}
.disabledBorderedContainer {
  composes: disabledBorderedContainer from './ChipButtonBase.module.css';
}

.mainColorLabel {
  composes: label from './ChipButtonBase.module.css';
  color: FEEDBACK_DANGER_DARK;
}
.grayLabel {
  composes: grayLabel from './ChipButtonBase.module.css';
}
.whiteLabel {
  composes: whiteLabel from './ChipButtonBase.module.css';
}

.mainColorIcon {
  composes: mainColorIcon from './ChipButtonBase.module.css';
  color: FEEDBACK_DANGER_DARK;
}
.grayIcon {
  composes: grayIcon from './ChipButtonBase.module.css';
}
.whiteIcon {
  composes: whiteIcon from './ChipButtonBase.module.css';
}
