@value BUTTON_BLUE_DARK, BUTTON_BLUE_LIGHT, BUTTON_BLUE_LIGHTEST, FEEDBACK_ATTENTION_LIGHTEST, FEEDBACK_ATTENTION_LIGHT, FEEDBACK_ATTENTION_DARKEST, FEEDBACK_DANGER_DARKEST, FEEDBACK_DANGER_LIGHT, FEEDBACK_DANGER_LIGHTEST, NEUTRAL_XLIGHT from "./common/colors.module.css";

.container,
.errorContainer,
.transientErrorContainer {
  width: '100%';
  height: 56px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-bottom: 8px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid BUTTON_BLUE_LIGHTEST;
  justify-content: space-between;
}

.errorContainer {
  background-color: FEEDBACK_DANGER_LIGHTEST;
  border: 1px solid FEEDBACK_DANGER_LIGHT;
}

.transientErrorContainer {
  background-color: FEEDBACK_ATTENTION_LIGHTEST;
  border: 1px solid FEEDBACK_ATTENTION_LIGHT;
}

.imageWrapper,
.loadingImagePlaceholder,
.errorImagePlaceholder,
.transientErrorImagePlaceholder {
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  object-fit: cover;
}

.loadingImagePlaceholder {
  background-color: NEUTRAL_XLIGHT;
  border-radius: 8px;
}

.errorImagePlaceholder {
  background-color: FEEDBACK_DANGER_LIGHT;
  border-radius: 8px;
}

.transientErrorImagePlaceholder {
  background-color: FEEDBACK_ATTENTION_LIGHT;
  border-radius: 8px;
}

.fileName,
.errorFileName,
.transientErrorFileName {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}

.fileName {
  color: BUTTON_BLUE_DARK;
}

.errorFileName {
  color: FEEDBACK_DANGER_DARKEST;
}

.transientErrorFileName {
  color: FEEDBACK_ATTENTION_DARKEST;
}

.closeButton,
.permanentErrorButton {
  background-color: transparent;
  border: 0;
  color: BUTTON_BLUE_LIGHT;
  margin-left: auto;
}

.transientErrorButton {
  color: FEEDBACK_ATTENTION_DARKEST;
  margin-left: auto;
  padding: 1px 6px;
}

.closeButton:hover,
.permanentErrorButton:hover,
.transientErrorContainer:hover {
  cursor: pointer;
}

.permanentErrorButton {
  color: FEEDBACK_DANGER_DARKEST;
}

.directionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.loadingTextPlaceholder {
  width: 200px;
  height: 20px;
  background-color: NEUTRAL_XLIGHT;
  border-radius: 8px;
}

.fileNotSupported {
  background-color: FEEDBACK_DANGER_LIGHT;
  border: 1px solid FEEDBACK_DANGER_LIGHT;
}
