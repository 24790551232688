@value FEEDBACK_DANGER_DARK from "./common/colors.module.css";

.cardsContainer {
  margin-top: 16px;
}
.buttonsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.warningContainer {
  color: FEEDBACK_DANGER_DARK;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.warningIcon {
  margin-right: 8px;
}
.warningTopMargin {
  margin-top: 8px;
}
.warningText {
  color: FEEDBACK_DANGER_DARK;
  font-family: Nunito;
  font-size: 14px;
  font-style: Italic;
  font-weight: 400;
  line-height: 150%;
}
