@value BUTTON_BLUE_DARK, LIGHT_BLUE, NEUTRAL_XXDARK from "./common/colors.module.css";

.container {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  max-width: 600px;
  padding: 0px 16px;
}
.descriptionText {
  color: NEUTRAL_XXDARK;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}
.codeContainer {
  background-color: LIGHT_BLUE;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  margin: 0px 24px 48px 24px;
  padding: 8px 24px 8px 24px;
}
.codeText {
  color: NEUTRAL_XXDARK;
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.4em;
  line-height: 48px;
  /* Por conta do letter-spacing aplicar o espaçamento no último caracter nós precisamos usar essa margem negativa para fazer com que a div do texto tenha exatamente o tamanho dele (texto).
    Isso possibilita que o texto fique centralizado no container */
  margin-right: -0.4em;
}
.newSubmission {
  color: BUTTON_BLUE_DARK;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 128px;
}
.newSubmission:hover {
  cursor: pointer;
  text-decoration: underline;
}
