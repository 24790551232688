body {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url(./Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local('Nunito Bold'), local('Nunito-Bold'),
    url(./Nunito-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: local('Nunito Italic'), local('Nunito-Italic'),
    url(./Nunito-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: local('Nunito BoldItalic'), local('Nunito-BoldItalic'),
    url(./Nunito-BoldItalic.ttf) format('truetype');
}
