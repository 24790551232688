@value BUTTON_BLUE_DARK, TRANSPARENT_BLACK, NEUTRAL_LIGHT, NEUTRAL_LIGHTEST from "./common/colors.module.css";

.container {
  height: 285px;
  width: 343px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  background-color: NEUTRAL_LIGHTEST;
  border: 1px solid NEUTRAL_LIGHT;
  padding: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.container:focus {
  outline: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: TRANSPARENT_BLACK;
}

.descriptionText {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.cancelButton {
  align-self: center;
  color: BUTTON_BLUE_DARK;
  margin-top: 15px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
}

.cancelButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
