@value LIGHT_BLUE, NEUTRAL_XXDARK from "./common/colors.module.css";

.button {
  display: flex;
  flex: 1;
  justify-content: center;
}
.container {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  max-width: 600px;
  padding: 0px 16px;
}
.descriptionText {
  color: NEUTRAL_XXDARK;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0px 24px 0px;
}
