@keyframes leftSpin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes rightSpin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

@keyframes containerRotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fillUnfillRotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

.wrapper {
  display: inline-block;
  position: relative;
  animation: containerRotate 1568ms linear infinite;
}

.spinnerLayer {
  opacity: 1;
  animation: fillUnfillRotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  position: absolute;
  width: 100%;
  height: 100%;
}

.circleClipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
  box-sizing: border-box;
}

.circle {
  width: 200%;
  height: 100%;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
}

.leftCircle {
  animation: leftSpin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
}

.rightCircle {
  animation: rightSpin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}

.gapPatch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gapPatchCircle {
  width: 1000%;
  left: -450%;
}
