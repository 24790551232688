@value NEUTRAL_LIGHTEST, NEUTRAL_LIGHT, NEUTRAL_DARK, NEUTRAL_XDARK from "./common/colors.module.css";

.container {
  align-items: center;
  border: none;
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  max-width: none;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  width: auto;
}

.filledContainer {
  composes: container;
  border: none;
}
.filledContainer:focus {
  border: 5px double NEUTRAL_LIGHTEST;
}
.filledContainer:hover:active {
  border: none;
}
.disabledFilledContainer {
  composes: container;
  background-color: NEUTRAL_LIGHT;
}

.borderedContainer {
  composes: container;
  background-color: NEUTRAL_LIGHTEST;
}
.borderedContainer:focus {
  background-color: NEUTRAL_LIGHTEST;
}
.borderedContainer:hover:active {
  border: none;
}
.borderedContainer:hover:active > .label,
.borderedContainer:hover:active > .mainColorIcon {
  color: NEUTRAL_LIGHTEST;
}
.disabledBorderedContainer {
  composes: container;
  background-color: NEUTRAL_LIGHTEST;
  border: 1px solid NEUTRAL_DARK;
}

.label {
  display: flex;
  flex-direction: row;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}
.grayLabel {
  composes: label;
  color: NEUTRAL_XDARK;
}
.whiteLabel {
  composes: label;
  color: NEUTRAL_LIGHTEST;
}

.mainColorIcon {
}
.grayIcon {
  color: NEUTRAL_XDARK;
}
.whiteIcon {
  color: NEUTRAL_LIGHTEST;
}
